export default function fetchFe(url, opts = {}) {
  let { ctx, headers = {} } = opts
  delete opts.ctx

  if (!process.browser && !ctx) throw Error('Forgot to pass ctx for fetch')

  opts.credentials = 'include'

  if (ctx && ctx.req) {
    let host = ctx.req.headers.host

    if (host.includes('localhost')) {
      url = 'http://' + host + url
    } else if (
      host.endsWith('strivedigital.org') ||
      host.endsWith('strivemessaging.org')
    ) {
      url = 'https://' + host + url
    } else {
      throw new Error('Unrecognized host, bailing')
    }
    headers['Cookie'] = ctx.req.headers.cookie
  }

  opts.headers = headers

  return fetch(url, opts)
}
