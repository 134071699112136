export enum EnumFormType {
  fbLead = 'FB_LEAD',
  form = 'FORM',
  letterCampaign = 'LETTER_CAMPAIGN',
  petition = 'PETITION',
  salesforce = 'SALESFORCE'
}

export enum EnumGroupableType {
  activistCode = 'activistCode',
  actionKit = 'actionKit',
  actionKitActionfield = 'actionKitActionfield',
  actionKitPage = 'actionKitPage',
  actionKitUsergroup = 'actionKitUsergroup',
  eaCommitteeOptOut = 'eaCommitteeOptOut',
  event = 'event',
  form = 'form',
  import = 'import',
  keyword = 'keyword',
  simple = 'simple',
  smart = 'smart',
  spam = 'spam',
  salesforce = 'salesforce',
  onlineAction = 'onlineAction',
  access = 'access'
}

export enum EnumLastMessageDirection {
  incoming = 'incoming',
  outgoing = 'outgoing'
}

export enum EnumTriggerAction {
  AbFormExistingMember = 'AB_FORM_EXISTING_MEMBER',
  AbFormNewMember = 'AB_FORM_NEW_MEMBER',
  AbFormUniversal = 'AB_FORM_UNIVERSAL',
  AfterEvent = 'AFTER_EVENT',
  AkPageExistingMember = 'AK_PAGE_EXISTING_MEMBER',
  AkPageNewMember = 'AK_PAGE_NEW_MEMBER',
  AkPageUniversal = 'AK_PAGE_UNIVERSAL',
  AnFormSubmission = 'AN_FORM_SUBMISSION',
  AnLetterCampaignOutreach = 'AN_LETTER_CAMPAIGN_OUTREACH',
  AnPetitionSubmission = 'AN_PETITION_SUBMISSION',
  ApiExistingMemberOnce = 'API_EXISTING_MEMBER_ONCE',
  ApiExistingMemberRepeatable = 'API_EXISTING_MEMBER_REPEATABLE',
  ApiRepeatable = 'API_REPEATABLE',
  ApiRepeatableTwo = 'API_REPEATABLE_TWO',
  ApiSubmission = 'API_SUBMISSION',
  ApiUniversal = 'API_UNIVERSAL',
  BeforeEvent = 'BEFORE_EVENT',
  EventSignup = 'EVENT_SIGNUP',
  FbLeadExistingMember = 'FB_LEAD_EXISTING_MEMBER',
  FbLeadNewMember = 'FB_LEAD_NEW_MEMBER',
  FbLeadSubmission = 'FB_LEAD_SUBMISSION',
  FormSubmission = 'FORM_SUBMISSION',
  GroupJoinSubscribe = 'GROUP_JOIN_SUBSCRIBE',
  GroupJoinUniversal = 'GROUP_JOIN_UNIVERSAL',
  KeywordExistingMember = 'KEYWORD_EXISTING_MEMBER',
  KeywordSubscribe = 'KEYWORD_SUBSCRIBE',
  KeywordUniversal = 'KEYWORD_UNIVERSAL',
  OnlineActionExistingMember = 'ONLINE_ACTION_EXISTING_MEMBER',
  OnlineActionNewMember = 'ONLINE_ACTION_NEW_MEMBER',
  OnlineActionUniversal = 'ONLINE_ACTION_UNIVERSAL',
  Subscribe = 'SUBSCRIBE'
}

export enum EnumTriggerEntity {
  ActionKitPage = 'ActionKitPage',
  Campaign = 'Campaign',
  Event = 'Event',
  Form = 'Form',
  Group = 'Group',
  Keyword = 'Keyword',
  OnlineAction = 'OnlineAction',
  Source = 'Source'
}
